import { CommonSettings } from '@starsoft/common/interfaces/common-settings';

export type SettingsStateProps = {
  settings: CommonSettings;
};

export enum SettingsActions {
  SetSettings = 'settings/setSettings',
}

export interface SettingsBasePayload<T extends SettingsActions, V = null> {
  type: T;
  payload: V;
}

export type SetSettingPayload = SettingsBasePayload<
  SettingsActions.SetSettings,
  CommonSettings
>;

export type SettingsStatePayload = SetSettingPayload;
