import { PropsWithChildren, useReducer } from 'react';
import { CommonProviderProps } from './props';
import { CommonSettingsContext } from '@starsoft/common/context';
import { settingsReducer } from '@starsoft/common/react-reducer';

export function CommonProvider({
  children,
  settings,
}: PropsWithChildren<CommonProviderProps>) {
  const [state, dispatch] = useReducer(settingsReducer, { settings });

  return (
    <CommonSettingsContext.Provider value={{ state, dispatch }}>
      {children}
    </CommonSettingsContext.Provider>
  );
}
