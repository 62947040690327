module.exports = {
  locales: ['zh', 'ru', 'pt', 'fr', 'es', 'en', 'de', 'id'],
  loadLocaleFrom: (lang, ns) =>
    import(`./locales/${lang}/${ns}.json`).then(m => m.default),
  loader: false,
  keySeparator: ':',
  pages: {
    '*': ['common'],
    '/': ['common'],
  },
};
