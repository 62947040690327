import React from 'react';

import '@/styles/theme/globals.scss';

import { AppProps } from 'next/app';
import FramerProvider from '@/providers/FramerProvider';
import Seo from '@/components/core/Seo';
import { MetadataProps } from '@/components/core/Seo/props';
import PackageCommonSettingsProvider from '@/providers/PackageCommonSettingsProvider';
import appWithi18n from 'next-translate/appWithI18n';
import i18nConfig from '../../i18n';

function App({ Component, pageProps }: AppProps) {
  return (
    <main>
      <FramerProvider>
        <PackageCommonSettingsProvider>
          {!!pageProps?.metadata && (
            <Seo metadata={pageProps?.metadata as MetadataProps} />
          )}
          {/* @ts-ignore */}
          <Component {...pageProps} suppressHydrationWarning />
        </PackageCommonSettingsProvider>
      </FramerProvider>
    </main>
  );
}

export default appWithi18n(App as any, {
  ...i18nConfig,
  loader: false,
  skipInitialProps: true,
  loaderName: 'getInitialProps',
  keySeparator: '.',
});
