import {
  SettingsStateProps,
  SettingsStatePayload,
  SettingsActions,
} from './props';

let initialState: SettingsStateProps = {
  settings: null,
};

export function settingsReducer(
  state: SettingsStateProps = initialState,
  action: SettingsStatePayload,
): SettingsStateProps {
  switch (action.type) {
    case SettingsActions.SetSettings:
      return {
        ...state,
        settings: action.payload,
      };
    default:
      return state;
  }
}
